import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout/Layout"
import Video from "../components/Video/Video"
import "typeface-lato"

const IdeaPage = () => {
  return (
    <>
      <SEO title="Die Idee" />

      <Layout>
        <div className="idea-wrapper">
          <Video name="imagefilm.ogg" />
        </div>
      </Layout>
    </>
  )
}

export default IdeaPage
